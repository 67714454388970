import React from "react";
import {
  Stepper,
  Form,
  Email,
  PhoneNumber,
  NameTextField,
  Button,
  City,
  TextField,
  Hidden,
} from "@lmt/taskforce-react-form";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import './font/Allianz_Neo/index.css';
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import DoneOutline from "@mui/icons-material/DoneOutline";
import { loadTagCo } from "./lib/TagCo";

const MarginTopInputs = -0.5;

const theme = createTheme({
  palette: {
    root: {
      color: "#007AB3"
    },
    error: {
      main: '#F40F36',
    },
    action: {
      disabledBackground: '#B1D8E4',
      disabled: 'white'
    }
  },
  typography: {
    fontFamily: [
      "Allianz Neo",
    ],
    allVariants: {
      color: "#414141"
    }
  },
  components: {
    MuiButton: {
      defaultProps: {
        size: "large",
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        size: "small",
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: "small",
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: "dense",
        size: "small",
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: "dense",
      },
      styleOverrides: {
        root: {
          '&.Mui-error': {
            border: '2px solid #F40F36',
            padding: '4px',
            margin: '2px',
            width: 'auto',
            backgroundColor: '#FFE9EB',
            color: 'black',
            borderRadius: '4px',
          }
        }
      }
    },
    MuiInputBase: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiRadio: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: "dense",
        size: "small",
      }
    },
  },
});

const Text = styled("div")(({ theme }) => ({
  fontFamily: "Allianz Neo",
  fontSize: "100%",
  color: "#414141"
}));

const _addDatas = (data) => {
  const actionCo = window.contextTagCo.actionCo ?? ""
  const source = window.contextTagCo.source ?? ""
  return {
    ...data,
    origin: `${actionCo}:${source}`
  }
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Stepper
        steps={[
          <Form onSubmit={(data, results) => {
            window.tc_vars = {
              page_name: 'confirmation',
              conversion_file_id: results.id_lead,
            };
            loadTagCo('125');
          }} addDatas={_addDatas}>
            <Box mt={MarginTopInputs}>
              <TextField
                label="Dites-nous en plus sur votre besoin"
                required={false}
                variant="standard"
                name="coregistration_choices"
                inputProps={{
                  maxLength: 120
                }}
                helperText="120 caractères maximum"
                FormHelperTextProps={{ style: { textAlign: 'right' } }}
              />
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box mt={MarginTopInputs}>
                  <NameTextField
                    label="Nom"
                    name="last_name"
                    required="Veuillez saisir votre nom."
                    validatorMessage="Le champ nom est incorrect."
                    variant="standard"
                    delay={800}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box mt={MarginTopInputs}>
                  <NameTextField
                    label="Prénom"
                    name="first_name"
                    required="Veuillez saisir votre prénom."
                    validatorMessage="Le champ prénom est incorrect."
                    variant="standard"
                    delay={800}
                  />
                </Box>
              </Grid>
            </Grid>
            <Box mt={MarginTopInputs}>
              <Email label="Email"
                name="email"
                required="Merci de saisir votre adresse email."
                validatorMessage="Le champ email est incorrect."
                variant="standard"
                delay={1000} />
            </Box>
            <Box mt={MarginTopInputs}>
              <PhoneNumber
                label="Téléphone mobile"
                name="phone_number_mobile"
                required="Merci de saisir votre numéro de téléphone"
                validatorMessage="Le numéro de téléphone mobile doit contenir 10 chiffres."
                variant="standard"
                delay={800} />
            </Box>
            <Box mt={MarginTopInputs}>
              <City label="Ville"
                name="autocompletion_address"
                cityName="city"
                postcodeName="zipcode"
                cityInseeCodeName="insee_code"
                required="Le nom de la ville n'a pas été saisie."
                variant="standard" />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }} mt={MarginTopInputs}>
              <Button
                name="button"
                variant="contained"
                labelValidStep="Valider"
              />
            </Box>
            <Box mt={2}>
              <Text>* Champs obligatoires</Text>
            </Box>
            <Box mt={2}>
              <Text>Nous collectons vos données personnelles uniquement pour vous recontacter,
                par email et par téléphone, dans le cadre de votre projet d'assurance.
                Pour en savoir plus sur la protection de vos données,
                &nbsp;<a rel="noreferrer" href="https://www.allianz.fr/assurance-particulier/protection-donnees-personnelles-cnil-no-retarg.html" target="_blank">cliquez ici</a>.
              </Text>
            </Box>
            <Hidden name="url" value="https://new-assurance-vie.devis-allianz.fr/" />
          </Form>,
        ]}
        result={(
          <React.Fragment>
            <Grid container spacing={3} justify="center">
              <Box p="2rem">
                <DoneOutline color="success" style={{ fontSize: 100 }} />
              </Box>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Merci pour votre demande
                </Typography>
                <Typography variant="subtitle1">
                  Nous avons bien enregistré les informations que vous nous avez transmises. Un conseiller Allianz, proche de chez vous, vous recontactera prochainement pour vous proposer une étude personnalisée.
                </Typography>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      />
    </ThemeProvider>
  );
}

export default App;
