import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { loadTagCo } from "./lib/TagCo";

window.tc_vars = {
  page_name: 'form',
};
loadTagCo('125');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
