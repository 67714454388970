export function loadTagCo(code) {
  const url = `https://cdn.tagcommander.com/248/tc_Allianz_${code}.js`;

  (function _loadTagCo(i, s, o, g) {
    const a = s.createElement(o);
    const m = s.getElementsByTagName(o)[0];

    a.async = 1;
    a.src = `${g}?${new Date().getTime()}`;
    m.parentNode.insertBefore(a, m);
  })(window, document, 'script', url);
}
